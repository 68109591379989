.subheader-wrapper {
    background-size: cover;
    height: 57vh;
    display: flex;
    justify-content: center;
    padding-left: 10%;
    color: #fff;
    object-fit: cover;
    background-position: 35% 100%;
  }
  
  .subheader-wrapper p {
    font-size: 20px;
  }
  
  @media screen and (max-width: 950px) {
    .subheader-wrapper {
      height: 37vh;
      background-position: 85% 100%;
    }
  
    .subheader-wrapper p {
      font-size: 16px;
    }
  }
  