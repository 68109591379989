.subheader {
    font-size: 20px;
    font-weight: 300;
    line-height: 40px;
    color: var(--color-gray);
  }
  
  .img-text-container {
    padding: 40px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .img-wrapper {
    box-shadow: 0px 0px 60px 20px rgba(150, 162, 170, 0.11);
    border-radius: 20px;
    width: 30%;
    height: 475px;
    padding: 0.5rem;
    margin: 0 0.8rem;
  }
  
  .img-wrapper p {
    text-align: center;
    font-size: 16px;
    line-height: 30px;
  }
  
  .image {
    width: 90%;
    height: 170px;
    object-fit: cover;
    border-radius: 15px;
    background-position: right;
    background-repeat: no-repeat;
    opacity: 1;
    transition: 0.5s ease;
    backface-visibility: hidden;
    box-shadow: 0px 4px 60px 20px rgba(137, 143, 148, 0.21);
  }
  
  .img-hover-wrapper {
    height: 38%;
    position: relative;
    width: 100%;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img-hover-wrapper:hover .image {
    opacity: 0.25;
  }
  
  .img-hover-wrapper:hover .location-map {
    opacity: 0.25;
    transition: all 0.5s ease-in-out;
  }
  
  .img-hover-wrapper:hover .hover-btn-wrapper {
    opacity: 1;
  }
  
  
  .img-hover-wrapper:hover .hover-desc-wrapper {
    opacity: .7;
  }
  .img-hover-wrapper-v2 {
    height: 38%;
    position: relative;
    width: 100%;
    object-fit: cover;
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }
  .img-hover-wrapper-v2:hover .image {
    opacity: 0.25;
  }
  
  .img-hover-wrapper-v2:hover .hover-btn-wrapper {
    opacity: 1;
  }
  
  .txt-wrapper {
    height: 62%;
  }
  
  .hover-btn-wrapper {
    transition: 0.5s ease;
    
    opacity: 0;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    position: absolute;
    background-color: var(--color-theme);
  }
  
  .hover-desc-wrapper {
    transition: 0.5s ease;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    height: 100%;
    opacity: 0;
    text-align: center;
    position: absolute;
    background-color: var(--color-theme);
  }
  
  
  
  
  .hover-btn {
    background-color: transparent;
    cursor: pointer;
    border: 3px solid var(--color-text);
    padding: 10px 15px;
    text-transform: uppercase;
    font-weight: 600;
    color:'white'
  }
  
  .hover-btn:hover {
    color: var(--color-gray);
    border: 3px solid var(--color-gray);
    transition: all 0.25s ease-in-out;
  }
  
  .solution-title {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @media screen and (max-width: 950px) {
    .subheader {
      font-size: 16px;
      line-height: 1.25;
    }
  
    .img-wrapper {
      box-shadow: 0px 0px 60px 20px rgba(150, 162, 170, 0.11);
      border-radius: 20px;
      width: 30%;
      height: 475px;
      padding: 28px 15%;
      margin: 0;
    }
  
    .img-wrapper p {
      font-size: 14px;
      line-height: 30px;
    }
  
    .home-solutions {
      display: none;
    }
  
    .img-text-container {
      flex-direction: column;
      padding: 16px 30px 0;
    }
  
    .solution-title {
      height: 20%;
      margin-top: 1rem;
    }
  }
  
  .repair-wrapper {
    background-color: var(--color-text);
    margin-top: 150px;
    padding: 2.5rem 0 4.5rem;
  }
  
  .repair-wrapper h2 {
    color: #fff;
  }
  
  .repair-wrapper p {
    color: #fff;
    width: 70%;
    text-align: center;
    margin-top: 20px;
  }
  
  .dot-pattern {
    position: relative;
    bottom: 130px;
    margin-bottom: -70px;
  }
  
  @media screen and (max-width: 950px) {
    .repair-wrapper {
      background-color: var(--color-text);
      margin-top: 32px;
      padding: 0.5rem 0 2.5rem;
    }
  }

  /* .characteristic-row {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
} */

.characteristic-card-wrapper {
    background: #fbfbfb;
    border: 1px solid #ececec;
    border-radius: 20px;
    padding: 1.5rem;
    margin: 0 0.3rem;
    width: 19%;
    height: 290px;
    text-align: center;
  }
  
  .characteristic-title {
    display: flex;
    margin-bottom: 1rem;
  }
  
  .characteristic-title img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  
  .even {
    position: relative;
    top: 80px;
  }
  
  @media screen and (max-width: 950px) {
    .characteristic-card-wrapper {
      padding: 1.5rem;
      margin: 0.5rem 0;
      width: 95%;
      height: 100%;
    }
  
    .even {
      top: 0;
    }
  
    .characteristic-row {
      width: 80vw;
      justify-content: space-between;
    }
  }
  
  .header-wrapper {
    background-size: cover;
    height: 70vh;
    display: flex;
    justify-content: center;
    padding-left: 10%;
    color: #fff;
    object-fit: cover;
    background-position: 60% 100%;
  }
  
  .header-wrapper p {
    width: 50%;
    font-size: 20px;
    font-weight: 300;
    line-height: 40px;
  }
  
  .learn-more {
    text-transform: uppercase;
    background: var(--color-gradient);
    color: #fff;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 20px 48px;
    border-radius: 10px;
    margin-top: 2rem;
    font-size: 18px;
  }
  
  .learn-more:hover {
    background: var(--color-gradient-darker);
    transition: all 0.3s ease;
  }
  
  @media screen and (max-width: 700px) {
    .header-wrapper p {
      width: 60%;
      text-align: left;
      font-size: 16px;
      line-height: 1.5;
      color: rgb(185, 185, 185);
    }
  
    .learn-more {
      padding: 12px 24px;
      border-radius: 10px;
      margin-top: 0.7rem;
      font-size: 14px;
    }
  
    .header-wrapper {
      height: 40vh;
    }
  }
  