.partner-grid {
    width: 15vw;
    height: 15vw;
    border: 1px solid #ececec;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .partner-grid img {
    width: 85%;
  }
  
  .partner-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80vw;
    padding: 8px 0;
  }
  
  .partners-desktop {
    display: block;
  }
  
  .partners-mobile {
    display: none;
  }
  
  @media screen and (max-width: 950px) {
    .partners-desktop {
      display: none;
    }
  
    .partners-mobile {
      display: block;
    }
  
    .partner-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 80vw;
      padding: 8px 0;
    }
  
    .partner-grid {
      width: 25vw;
      height: 25vw;
    }
  }
  