.footer-wrapper {
  background-color: rgb(240, 243, 248);
  margin-top: 80px;
  padding: 3.5rem 10%;
  bottom: 0;
}

.footer-logo {
  width: 37px;
  height: 37px;
  padding-right: 10px;
}

.footer-name-wrapper {
  display: flex;
  align-items: center;
}

.footer-name {
  font-size: 2.25rem;
  color: var(--color-theme);
  font-weight: bold;
}

.footer-col {
  width: 33%;
  flex-direction: column;
  padding: 0 1rem 3rem;
}

.fab {
  border-radius: 50%;
  border: 1px solid rgb(150, 150, 150);
  width: 37px;
  height: 37px;
  font-size: 22px;
  color: var(--color-gray);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.fa-facebook-f:hover {
  color: #3b5998;
  border-color: #3b5998;
  transition: all 0.25s ease-in-out;
}

.fa-phone-alt{
  color: var(--color-gray);

}

.fa-linkedin-in:hover {
  color: #0077b5;
  border-color: #0077b5;
  transition: all 0.25s ease-in-out;
}

.fa-twitter:hover {
  color: #1da1f2;
  border-color: #1da1f2;
  transition: all 0.25s ease-in-out;
}

.footer-social {
  display: flex;
  align-items: center;
}

.location-map {
  width: 100%;
  margin-top: 10px;
  opacity: 1;
  border-radius: 16px;
}

.footer-subhead {
  color: var(--color-theme);
  line-height: 50px;
}

hr {
  background-color: rgb(211, 211, 211);
  border: none;
  height: 1px;
}

.copyright {
  font-size: 14px;
  text-align: center;
  line-height: 2;
  font-weight: 300;
}

.footer-contact {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.fas {
  font-size: 20px;
  padding-right: 8px;
}

.footer-contact-heading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.fa-envelope {
  margin-top: 12px;
  color: var(--color-gray);

}

.footer-contact-subhead {
  font-size: 20px;
  font-weight: 400;
}

@media (min-width: 1150px) {
  .footer-col .hover-btn-wrapper {
    bottom: 50%;
  }
}

@media screen and (max-width: 950px) {
  .footer-wrapper {
    margin-top: 40px;
    padding: 2.5rem 10%;
  }

  .img-txt-container.flex-align-start {
    flex-direction: column;
  }

  .footer-col {
    width: 90%;
    flex-direction: column;
    padding: 0 0.75rem 1.2rem !important;
  }

  .footer-logo {
    width: 35px;
    height: 35px;
    padding-right: 8px;
  }

  .fab {
    width: 32px;
    height: 32px;
    font-size: 18px;
    margin-right: 10px;
    margin-top: 10px;
  }

  .footer-name {
    font-size: 2rem;
  }

  .footer-subhead {
    line-height: 32px;
  }

  .fas {
    font-size: 14px;
    padding-right: 8px;
  }

  .footer-contact-subhead {
    font-size: 16px;
  }

  .copyright {
    font-size: 12px;
    line-height: 2;
  }

  .footer-name {
    font-size: 2rem;
  }

  .footer-logo {
    width: 32px;
    height: 32px;
    padding-right: 5px;
  }
}
