@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cursor-pointer{
  cursor: pointer;
}

:root {
  --font:'SF UI Text','outfit',sans-serif;
  --color-bg: #fff;
  --color-text: #272c49;
  --color-gray: #6e7f8f;
  --color-yellow: #ffa33d;
  --color-border: #ececec;
  --color-green: #29d1af;
  --color-theme: #28a5df;
  --color-theme-darker: #068bc9;
  --color-gradient: linear-gradient(135.43deg, #3bc7fd 0.5%, #176ebb 99.76%);
  --color-gradient-darker: linear-gradient(
    135.43deg,
    var(--color-theme) 0.5%,
    #176ebb 99.76%
  );
}

* {
  margin: 0;
  padding: 0;
  font-family: var(--font);
}

button{
  font-family: var(--font) !important;
}

h1 {
  /* color: #fff; */
  font-weight: 700;
  font-size: 80px;
  width: 60%;
  margin-bottom: 1rem;
  letter-spacing: 1.2px;
}

h2 {
  /* color: var(--color-text); */
  line-height: 90px;
  font-size: 50px;
  letter-spacing: 1.2px;
}

h3 {
  font-size: 26px;
  line-height: 45px;
  font-weight: 500;
  margin-bottom: 10px;
}

h4 {
  color: var(--color-theme-darker);
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
}

h5 {
  color: var(--color-text);
  font-weight: 600;
  font-size: 21px;
  line-height: 30px;
  /* text-align: center; */
  margin: 10px 0;
}

p {
  font-size: 16px;
  line-height: 26px;
  color: var(--color-gray);
  font-weight: 300;
  text-align: justify;
}

body {
  background-color: #fff;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-align-start {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.flex-col {
  flex-direction: column;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2.5rem 7%;
}

.blue-divider {
  background: var(--color-gradient);
  width: 85px;
  height: 5px;
  border-radius: 20px;
  margin-top: 50px;
}

.solution-header-caption {
  width: 50%;
}

@media screen and (max-width: 950px) {
  h1 {
    font-size: 32px;
    margin-bottom: 5px;
  }

  h2 {
    line-height: 40px;
    font-size: 28px;
  }

  h3 {
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 6px;
    text-align: justify;
  }

  h4 {
    font-size: 18px;
    line-height: 1.5;
  }

  h5 {
    font-size: 16px;
    line-height: 20px;
    margin: 8px 0;
  }

  p {
    font-size: 14px;
    line-height: 1.5;
  }

  .blue-divider {
    width: 50px;
    margin-bottom: 1rem;
  }

  .container {
    padding: 0.5rem 7%;
  }

  .flex-align-start {
    align-items: baseline;
  }

  .solution-header-caption {
    width: 60%;
    color: rgb(185, 185, 185);
    text-align: left;
  }
}

.desc-img-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 7% 10%;
}

.desc-wrapper {
  width: 47%;
  padding-right: 2rem;
}

.desc-img-wrapper {
  width: 47%;
  max-width: 420px;
  height: 350px;
  border-radius: 20px;
  box-shadow: 0px 0px 60px 20px rgba(150, 162, 170, 0.11);
  padding: 1rem;
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.desc-img-wrapper img {
  width: 90%;
  max-width: 400px;
  height: 90%;
  object-fit: cover;
  border-radius: 15px;
  box-shadow: 0px 4px 60px 20px rgba(137, 143, 148, 0.21);
}

@media screen and (max-width: 950px) {
  .desc-img-container {
    flex-direction: column-reverse;
  }

  .desc-wrapper {
    width: 90%;
    padding-right: 0;
  }

  .desc-img-wrapper {
    width: 90%;
    height: 220px;
    margin-left: 0;
    margin-bottom: 1rem;
  }
}

.solution-gallery-title {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.solution-gallery-title-2 {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 950px) {
  .solution-gallery-wrapper {
    width: 95%;
    padding: 1.5rem;
    margin-bottom: 1.2rem;
  }

  .solution-gallery-title {
    padding-top: 10px;
  }

  .solution-gallery-title-2 {
    padding-top: 10px;
  }
}
.solar-wrapper {
  max-width: 800px;
  width: 70vw;
}

.solar-img-wrapper {
  box-shadow: 0px 0px 60px 20px rgba(150, 162, 170, 0.11);
  border-radius: 20px;
  width: 65vw;
  padding: 2rem;
  background-color: #fff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  object-fit: contain;
}

.solar-img-wrapper img {
  height: 350px;
  width: 47%;
  object-fit: contain;
}

@media screen and (max-width: 950px) {
  .solar-wrapper {
    width: 85vw;
  }

  .solar-img-wrapper {
    width: 85vw;
    padding: 0rem;
  }

  .solar-img-wrapper img {
    height: 200px;
  }
}

.noise-features-wrapper {
  background-color: #fbfbfb;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 7% 10%;
}

.toggle-wrapper {
  background-color: #fff;
  border: 1px solid #ececec;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  width: 60%;
  max-width: 500px;
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.untoggled-header {
  color: var(--color-gray);
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 8px 10px;
}

.toggled-header {
  width: 100%;
  border-radius: 20px;
  background-color: #6e7f8f;
  color: #fff;
  transition: color 0.5s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 8px 10px;
}

.fa-check-circle,
.fa-leaf {
  color: var(--color-green);
  line-height: 28px;
}

.features {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 3rem 0;
}

.features-col {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 48%;
}

.noise-green-sys {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 3rem 10% 3rem 15%;
}

@media screen and (max-width: 950px) {
  .untoggled-header {
    width: 90%;
    padding: 8px 10px;
  }

  .toggled-header {
    width: 90%;
    padding: 8px 10px;
  }

  .toggle-wrapper {
    font-size: 16px;
    line-height: 1.2;
    width: 100%;
  }

  .features {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    flex-direction: column;
  }

  .features-col {
    width: 90%;
  }

  .noise-green-sys {
    padding: 1rem 0;
    width: 90%;
  }
}

.enquiry-wrapper {
  flex-direction: column;
}

.submit-btn {
  color: white;
  background: var(--color-gradient);
  outline: none;
  border: none;
  cursor: pointer;
  padding: 12px 40px;
  border-radius: 10px;
}

.submit-btn:hover {
  background: var(--color-gradient-darker);
  transform: all 0.3s ease-in-out;
}

.form-margin {
  margin-bottom: 25px !important;
  display: flex;
  justify-content: space-between;
}

.enquiry-form {
  width: 100%;
}

.form-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0 20px;
  width: 65%;
  padding: 65px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 30px 6px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 0px 30px 6px rgba(0, 0, 0, 0.04);
}

@media screen and (max-width: 950px) {
  .form-wrapper {
    margin: 1.5rem 0 20px;
    width: 80%;
    padding: 2rem;
  }

  .form-margin {
    flex-direction: column;
    margin: 0 !important;
  }

  .MuiFormControl-root {
    width: 100% !important;
    margin-bottom: 8px !important;
  }
}
