.navbar {
  /* background-color: var(--color-bg); */
  /* background-color: aliceblue; */
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
}

.main-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.nav-logo {
  color: var(--color-theme);
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2.25rem;
  flex: 3;
  text-transform: uppercase;
  font-weight: bold;
}

.logo {
  width: 49px;
  height: 49px;
}

.nav-menu {
  display: flex;
  flex: 7;
  justify-content: flex-end;
  list-style: none;
  text-align: center;
  margin-right: 4em;
}

.nav-links {
  color: var(--color-text);
  text-decoration: none;
  /* padding: 0.5rem 1rem; */
  /* height: 100%; */
  /* border-bottom: 3px solid transparent; */
}

.fa-code {
  margin-left: 0.8rem;
}

.nav-item {
  line-height: 40px;
  margin-right: 0.8rem;
  font-size: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: var(--color-theme);
}

.nav-item .active {
  color: var(--color-theme);
  border-bottom: 3px solid var(--color-theme);
}

.nav-icon {
  display: none;
}

.nav-item-contact {
  line-height: 40px;
  margin-right: 0.8rem;
  font-size: 1rem;
}

#contact {
  background: var(--color-gradient);
  color: var(--color-bg);
  padding: 10px 15px;
  margin: 0;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 10px;
  font-weight: bold;
}

#contact:hover {
  background: var(--color-gradient-darker);
  transition: all 1s ease;
}

@media (max-width: 950px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* border-top: 1px solid #fff; */
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: var(--color-theme);
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item .active {
    color: var(--color-bg);
    border-bottom: none;
  }

  .nav-item:hover:after {
    width: 100%;
    background: var(--color-bg);
  }

  .nav-item-contact {
    margin: 0;
  }

  #contact {
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    padding-left: 30px;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--color-theme);
  }

  .navbar {
    font-size: 1.2rem;
  }

  .nav-logo {
    margin-left: 1rem;
    font-size: 32px;
  }

}
