.current-item-image{
opacity: 1;
transition: opacity ease-in-out 0.4s;
}

.item-image{
opacity: 0;
}

.item-image, .current-item-image{
position: absolute;
max-width: 100%;
max-height: 100%;
}