.container-slider-header {
  width: 100%;
  height: 500px;
  position: relative;
  padding: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.container-slider .img-wrapper {
  width: 80%;
  margin: 0;
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.active-slide {
  opacity: 1;
  visibility: visible;
  /* transform: translateX(-100%); */

}

.btn-slide {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #f6f6f6;
  /* border: 1px solid rgba(197, 197, 197, 0.445); */
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 6px;
  box-shadow: 0px 0px 40px 5px rgba(187, 187, 187, 0.63);
}

.prev {
  position: absolute;
  top: 50%;
}

.next {
  position: absolute;
  top: 50%;
  left: 88%;
}

.container-dots {
  position: absolute;
  bottom: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.dot {
  width: 20px;
  height:.25rem;
  border-radius: 10%;
  border: .1px solid #f1f1f1;
  margin: 0 5px;
  background: #f1f1f1;
}

.dot.active-dot {
  background: rgb(32, 32, 32);
}

@media screen and (max-width: 950px) {
  /* .container-slider {
    display: flex;
  } */

  .active-slide {
    visibility: visible;
  }

}
