.product-image {
  height: 70%;
  text-align: center;
}

.product-text {
  height: 30%;
}

.product-image:hover img {
  opacity: 0.25;
  transition: all 0.5s ease-in-out;
}

.product-image .hover-btn-wrapper {
  bottom: 50%;
}

.product-pg-container {
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 950px) {
  .product-pg-container {
    flex-direction: column;
    margin-top: 0;
  }

  .product-pg-container .first {
    margin-top: 1rem;
  }
}
